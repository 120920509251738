import autoprefixer from "autoprefixer";

const landingStyle = (theme) => ({
    btnBuy: {
        background: '#1291F9',
        borderRadius: 40,
        height: 74,
        width: 310,
        color: 'white',
        fontSize: 22,
        fontWeight: 700,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        marginTop: 30,
    },
    btnPurchase: {
        background: '#1291F9',
        height: 66,
        width: 185,
        color: 'white',
        fontSize: 19,
        fontWeight: 700,
        borderRadius: 40,
    },
    pricePlan: {
        maxWidth: 400,
        paddingTop: 46,
        paddingBottom: 20,
        '& ul': {
            marginTop: 5,
        },
        '& li': {
            marginBottom: 16,
        }
    },
    featureBullet: {
        background: '#37BFFF',
        width: 55,
        height: 55,
        borderRadius: 55,
        marginRight: 11,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    videoTag: {
        zIndex: 1000,
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 9,
        width: 460,
        height: 'auto',
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 35,
        paddingRight: 35,
        position: 'absolute',
        top: '50%',
        transform: 'translate(-25% ,-50%)',
        left: '60%',
        transition: 'all .3s',
        '&.play': {
            left: 0,
            width: '90%',
            height: 'auto',
            transform: 'translate(5% ,-50%)',
            paddingBottom: 12,
            transition: 'all .3s',
        },
        '& img': {
            marginTop: 16,
            marginBottom: 16,
            width: 37,
            height: 37
        },
        '& div': {
            textAlign: 'center'
        },
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    ourCompany: {
        position: 'relative',
        paddingBottom: 80,
        overflowY: 'hidden',
        '&::before': {
            content: '"our"',
            color: 'rgba(18, 145, 249, 0.05)',
            letterSpacing: '0.005em',
            fontSize: 600,
            position: 'absolute',
            bottom: 150,
            lineHeight: '300px',
            fontWeight: 700,
            display: 'flex',
        }
    },
    mainContainer: {
        background: 'url("/assets/img/newui/landing/main-photo.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '@media (min-width: 1152px)': {
            paddingTop: 50,
            paddingBottom: 50,
        },
        '@media (min-width: 1440px)': {
            paddingTop: 120,
            paddingBottom: 120,
        }
    },
    bgSide: {
        width: '25%',
        background: '#1291F9',
        position: 'relative',
    },
    bgVideoWrapper: {
        '& video:focus': {
            outline: 'none'
        },
        '& video:active': {
            outline: 'none'
        },
        '& .pause-icon': {
            display: 'none'
        },
        '&:hover .pause-icon': {
            display: 'block'
        },
    },
    videoThumb: {
        width: '100%',
        maxHeight: 700,
        objectFit: 'cover',
    },
    btnAction: {
        borderRadius: 7,
        width: 105,
        height: 34,
        color: 'white',
    }
});

export default landingStyle;