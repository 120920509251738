import React from 'react';
import { withStyles } from '@material-ui/core';

const LandingFooter = (props) => {
    const { classes } = props;
    return (
        <div className={ classes.footerContainer }>
            <div style={{ height: 70, borderBottom: '1px solid #FFF' }}>
            </div>
            <div className="flex" style={{ padding: '28px 50px' }}>
                <div className="flex-1 flex flex-col px-3">
                    <div className="subtitle">
                        United States
                    </div>
                    <div className="subcontent">
                        22525 SE 64th Pl
						<br/>
						Issaquah
                        <br/>
                        WA 98027
                    </div>
                </div>
                {/* <div className="flex-1 flex flex-col px-3">
                    <div className="subtitle">
                        Brazil
                    </div>
                    <div className="subcontent">
                        Alameda Araguaia, 1142 -
						<br/>
						Alphaville Industrial, Barueri -
						<br/>
						SP, 06455-000
                    </div>
                </div> */}
                <div className="flex-1 flex flex-col px-3">
                    <div className="subtitle">
                        Contact us
                    </div>
                    <div className="subcontent">
                        Info@iclevercheck.com
						<br/>
						sales@iclevercheck.com
						<br/>
						Support@iclevercheck.com
                    </div>
                </div>
                <div className="flex-1 flex justify-center items-start py-2">

                    <img src="/assets/img/newui/landing/facebook-icon.svg" />
                    <img src="/assets/img/newui/landing/instagram-icon.svg" />
                    <img src="/assets/img/newui/landing/linkedin-icon.svg" />
                </div>
            </div>
            <div style={{ height: 70, textAlign: 'center', fontSize: 30, fontWeight: 700, borderTop: '1px solid #FFF', color: 'white', lineHeight: '70px' }}>
                All Rights Reserved © 2020
            </div>
        </div>
    )
}

const styles = (theme) => ({
    footerContainer: {
        background: 'linear-gradient(180deg, rgba(10, 66, 209, 0.53) 0%, rgba(255, 255, 255, 0) 100%), #1291F9',
        color: '#FFF',
        '& .subtitle': {
            fontSize: 30,
            fontWeight: 700,
        },
        '& .subcontent': {
            fontSize: 16,
        },
        '& img': {
            marginLeft: 10,
            marginRight: 10,
        }
    },
});

export default withStyles(styles)(LandingFooter);