import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

class Question extends Component
{
    state = {
        expanded: false,
    }
    render() {
        const { classes, question, answer, ...props } = this.props;
        const { expanded } = this.state;
        return (
            <div className={ classes.container } {...props}>
                <div className={ classes.questionContainer } onClick={() => this.setState({ expanded: !expanded })}>
                    { question }
                    <img src="/assets/img/newui/landing/plus-icon.svg" />
                </div>
                {
                    expanded && 
                        <div className={ classes.answerContainer }>
                            { answer }
                        </div>
                }
            </div>
        )
    }
}

const styles = (theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            marginLeft: 'auto'
        }
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(30, 30, 30, 0.2)',
        fontSize: 30,
        paddingTop: 45,
        paddingBottom: 12,
        paddingLeft: 5,
        paddingRight: 5,
        cursor: 'pointer'
    },
    answerContainer: {
        borderTop: '1px solid rgba(30, 30, 30, 0.2)',
        fontSize: 18,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 5,
        paddingRight: 5,
    }
});

export default withStyles(styles)(Question);