import * as Type from '../actions/types';

const initialState = {
    socket: null,
    contacts: {},
    messages: {},
    recentContactIDs: [],
    currentTargetUser: null,
};

export const chat = (state = initialState, action) => {
  switch (action.type) {
    case Type.CHAT:
      return Object.assign(state, action.payload);
    default:
      return state;
  }
};
