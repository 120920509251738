import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from "./utils/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { withRouter } from 'react-router'
import { withStyles } from "@material-ui/core";
import AppStyle from "./AppStyle";
import Landing from 'views/Landing';

class App extends Component {

  state = {
    loginInfo: {
      user: {
        first_name: "",
        last_name: ""
      }
    }
  }

  constructor(props) {
    super(props);
    if (typeof this.props["account"] != 'undefined') {
      this.state.loginInfo = {
        ...this.state.loginInfo,
        ...this.props["account"]["loginStatus"]
      };
    }

    // Route change event
    this.props.history.listen((location, action) => {
      try {
        let pages = document.getElementsByClassName('main-container');
        if (typeof pages !== 'undefined' && pages.length > 0 && pages[0]) {
          setTimeout(() => {
            try {
              pages[0].scrollTop = 0;
            } catch (error) {
              console.log(error);
            }
          }, 100);
        }
      } catch (err) {
      }
    });
  }

  componentDidMount() {

  }

  render() {
    return (
      <Route exact path="/" component={ Landing } />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(AppStyle)(App))
);
