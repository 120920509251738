export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_FORGOT = 'LOGIN_FORGOT';
export const PWD_RESET = 'PWD_RESET';

export const CM_RESULT = 'CM_RESULT';
export const CM_ACTION_RESULT = 'CM_ACTION_RESULT';
export const CM_DATA = 'CM_DATA';
export const CM_BUDGET_DEL = 'CM_BUDGET_DEL';
export const CM_APPRV_RESULT = 'CM_APPRV_RESULT';
export const CM_ERROR = 'CM_ERROR';
export const CM_ACTION_W9SETUP = 'W9_SETUP';
export const CM_ORG_LIST = 'CM_ORG_LIST';
export const CM_INIT_DATA = 'CM_INIT_DATA';
export const CM_CUSTOMER_DATA = 'CM_CUSTOMER_DATA';
export const CM_CUSTOMER_INFO = 'CM_CUSTOMER_INFO';
export const CM_INVOICE_LIST = 'CM_INVOICE_LIST';
export const CM_PRINT_PDF = 'CM_PRINT_PDF';
export const CM_SEND_INVOICE = 'CM_SEND_INVOICE';
export const PROFILE_INFO = 'PROFILE_INFO';

export const CM_CAT_RESULT = 'CM_CAT_RESULT';
export const CM_CAT_ADD_RESULT = 'CM_CAT_ADD_RESULT';

export const CM_EVT_RESULT = 'EVT_RESULT';

export const ADM_BUDGET_INFO = 'ADM_BUDGET_INFO';

export const CM_PAY_LIST='CM_PAY_LIST';

export const FILE_UPLOAD = 'FILE_UPLOAD';

export const UI_SETTING = 'UI_SETTING';

export const CHAT = 'CHAT';

